<template>
	<span v-if="item.value === false || item.value === true">
		<font-awesome-icon class="text-success" v-if="item.value === true" :icon="['fal', 'check-circle']" />
		<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
	</span>
	<span v-else-if="isLinkOrEmail(item.value)" v-html="isLinkOrEmail(item.value)"></span>
	<span v-else v-html="cleanHTML(item.value)"></span>
</template>

<script>
import xss from 'xss'

export default {
	name: 'DefaultCell',
	props: {
		item: Object
	},
	methods: {
		isLinkOrEmail(val) {
			let link, mail
			let new_val = val

			if(typeof(val) == "string") {
				link = val.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g)
				mail = val.match(/\S+@\S+\.\S+/)
			}

			if(link) {
				link = link[0].replace(/,/g, '')
				new_val = val.replace(link, '<a href="' + link +'" target="_blank">' + link + '</a>')
			}
			else if(mail) {
				mail = mail[0].replace(/,/g, '')
				new_val = val.replace(mail, '<a href=mailto:' + mail +'>' + mail + '</a>')
			}

			return val == new_val ? false : new_val
		},
		cleanHTML(input) {
			if(input === 0) return input
			return xss(input)
		}
	}
}
</script>
